import './styles.scss';

import React, { useRef } from 'react';
import { useSprings, animated } from 'react-spring';
import cx from 'classnames';
import useViewport from '@assets/scripts/hooks/useViewport';
import { getElementOffsetTop } from '@assets/scripts/helpers/getElementOffsetTop';

const DIST_RATIO = 0.2; // 1 = translate the text completely (from aligned-left to aligned-right)

const SectionTitle = ({ className, title }) => {
	const refEl = useRef(null);
	const refOriginal = useRef(null);
	const refClone = useRef(null);
	const rootClass = cx('section-title-wrapper', className);

	const measures = useRef({
		componentRect: undefined,
		componentHeight: undefined,
		componentDistFromDocumentTop: undefined,
		titleWidth: undefined,
	});

	const [springs, setSprings] = useSprings(2, (i) => ({
		translateX: 0,
		config: { mass: 5, tension: 280, friction: 120 },
	}));

	// when viewport size / scroll changes
	useViewport(
		({ viewportWH, scrollXY, viewportChanged }) => {
			// vars
			const [vw, vh] = viewportWH;
			const [, scrollY] = scrollXY;

			// TODO: find out how to optimise this! if we measure only in the beginning it fails, probably because the font is not yet loaded?
			// if (
			// 	viewportChanged ||
			// 	typeof measures.current.titleWidth === 'undefined'
			// ) {
			// }
			if (
				viewportChanged ||
				typeof measures.current.componentHeight === 'undefined' ||
				typeof measures.current.componentDistFromDocumentTop ===
					'undefined'
			) {
				measures.current.titleWidth = refOriginal.current.offsetWidth;
				measures.current.componentHeight = refEl.current.offsetHeight;
				measures.current.componentDistFromDocumentTop = getElementOffsetTop(
					refEl.current
				);
			}

			let progress;
			// component is below viewport
			if (scrollY + vh < measures.current.componentDistFromDocumentTop) {
				progress = 0;
			}
			// component is above viewport
			else if (
				scrollY >
				measures.current.componentDistFromDocumentTop +
					measures.current.componentHeight
			) {
				progress = 1;
			} else {
				progress =
					// distance travelled since entering viewport
					(vh -
						measures.current.componentDistFromDocumentTop +
						scrollY) /
					// total distance to travel
					(vh + measures.current.componentHeight);
			}

			// total distance to translate
			// title duplicated, so the real distance for a full visual translation is totalDist/2
			const totalDist =
				measures.current.titleWidth > vw
					? -1 * (measures.current.titleWidth - vw)
					: 0;

			// mutate
			setSprings((i) => {
				if (i === 0)
					return {
						translateX: progress * (totalDist / 2) * DIST_RATIO,
					};
				if (i === 1)
					return {
						translateX:
							totalDist - progress * (totalDist / 2) * DIST_RATIO,
						// delay: 50,
					};
				return;
			});
		},
		{ scroll: true }
	);

	return (
		<div
			className={rootClass}
			aria-hidden="true"
			data-section-title
			data-animation-page
			ref={refEl}
		>
			{springs.map(({ translateX }, i) =>
				i === 0 ? (
					<animated.h1
						key={i}
						className="section-title section-title--original"
						data-title={title}
						ref={refOriginal}
						style={{
							transform: translateX.interpolate(
								(x) => `translate3d(${x}px, 0, 0)`
							),
						}}
					>
						{title}
					</animated.h1>
				) : (
					<animated.p
						key={i}
						className="section-title section-title--clone"
						data-title={title}
						ref={refClone}
						style={{
							transform: translateX.interpolate(
								(x) => `translate3d(${x}px, 0, 0)`
							),
						}}
					>
						{title}
					</animated.p>
				)
			)}
		</div>
	);
};

export default SectionTitle;
