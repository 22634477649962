import React from 'react';

// components
import Layout from '@src/layouts';
import SectionTitle from '@components/atoms/SectionTitle';
import Detail from '@components/molecules/Detail';
import HeroPage from '@components/molecules/heros/HeroPage';

// assets
import imgEight from '@assets/images/eight.svg';

/**
 * Content related vars
 */

const pageClass = 'page-jobs';

const language = 'fr';

const footerRelatedLinks = [
	{
		title: 'Contact',
		url: '/fr/contact/',
	},
	{ title: 'Projets', url: '/fr/projets/' },
];

const ContactPage = (props) => (
	<Layout
		language={language}
		pageClass={pageClass}
		footerRelatedLinks={footerRelatedLinks}
		location={props.location}
		translations={[{ uri: '/en/jobs/', lang: 'en' }]}
		title="Offres d'emploi - Superhuit"
	>
		<HeroPage image={imgEight} imageY={54} title="Rejoindre" />
		<section className="section-jobs">
			<SectionTitle title="Postes ouverts" />

			<div className="grid">
				<div className="box text-content" data-animation-page>
					<p className="uptitle">Postes ouverts</p>
					<div className="list-two-columns element-spacing">
						{/* <Detail
							title="Assistant·e client partner - stagiaire en gestion de projet"
							text={[
								'Vivre une expérience d’agence de 6 mois en soutenant notre équipe de Client Partner à Lausanne.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Détails',
								url: '/fr/rejoindre/stage-client-partner/',
							}}
						/> */}
						{/* <Detail
							title="Brand Project Manager"
							text={[
								'Gérer des projets de branding, développer le portefeuille de clients et représenter notre équipe hautement qualifiée.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Détails',
								url: '/fr/rejoindre/brand-project-manager/',
							}}
						/> */}
						{/* <Detail
							title="Web Project Manager"
							text={[
								'Gérer des projets de haute qualité avec notre équipe créative à Lausanne.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Détails',
								url: '/fr/rejoindre/web-project-manager/',
							}}
						/> */}
						<Detail
							title="Tech Lead"
							text={[
								'Construire le futur de l’agence sur le plan technique, en étroite collaboration avec l’équipe et les associés.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Détails',
								url: '/fr/rejoindre/tech-lead/',
							}}
						/>
						{/* <Detail
							title="Développeur·se Web Back-end"
							text={[
								'Concevoir et développer des solutions web de qualité en PHP et Javascript avec notre équipe talentueuse.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Détails',
								url: '/fr/rejoindre/backend-developer/',
							}}
						/> */}
						{/* <Detail
							title="Développeur·se Web Front-end"
							text={[
								'Développer des expériences web de haute qualité avec notre équipe créative.',
								// 'Où: Lausanne, Suisse',
							]}
							link={{
								title: 'Détails',
								url: '/fr/rejoindre/frontend-developer/',
							}}
						/> */}
					</div>
				</div>
			</div>
		</section>
	</Layout>
);

export default ContactPage;
